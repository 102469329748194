<script>
	var form = {
		formState: 'input',
		recaptchaErr: "",
		messageSent: false,
		isValid: false,
		filesMaxSize: 5000000,
		filesTypes: [
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			"application/vnd.ms-excel",
			"application/x-zip-compressed",
			"application/zip",
			"application/x-7z-compressed",
			"application/vnd.rar",
			"image/png",
			"image/jpeg",
			"image/svg+xml",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			"application/pdf"
		],
		filesMax: 3,
		files: [],
		filesLength: 0,
		fileInputState: "files1",
		fileInputs: {
			"files1": {
				hasFile: false
			},
			"files2": {
				hasFile: false
			},
			"files3": {
				hasFile: false
			}
		},
		fileError: "",
		removeFile: (index, id) => {
			document.getElementById(id).value = "";
			form.files.splice(index,1);
			form.fileInputs[id].hasFile = false;

			if(form.fileInputs.files1.hasFile == false) {
				form.fileInputState = "files1";
			} else if(form.fileInputs.files2.hasFile == false) {
				form.fileInputState = "files2";
			} else if(form.fileInputs.files3.hasFile == false) {
				form.fileInputState = "files3";
			}

			form.filesLength--;
			document.getElementById('nois').value = form.filesLength;
		},
		addFiles: (id) => {
			var fileToAdd = document.getElementById(id).files[0];
			var isFileTypeOk = false;

			if(fileToAdd.size <= form.filesMaxSize) {
				for (let i = 0; i < form.filesTypes.length; i++) {
					if(fileToAdd.type == form.filesTypes[i]) {
						isFileTypeOk = true;
						break;
					}
				}

				if(isFileTypeOk) {
					form.files.push({
						id: id,
						name: fileToAdd.name
					});
					form.filesLength++;
					form.fileInputs[id].hasFile = true;

					for (var fi in form.fileInputs) {
						if (form.fileInputs.hasOwnProperty(fi)) {
							if(!form.fileInputs[fi].hasFile) {
								form.fileInputState = fi;
								break;
							}
						}
					}

					form.fileError = "";
					document.getElementById('nois').value = form.filesLength;
				} else {
					document.getElementById(id).value = "";
					form.fileError = "le fichier " + fileToAdd.name + " n'a pas été ajouté : le fichier est dans un format non accepté";
				}
			} else {
				document.getElementById(id).value = "";
				form.fileError = "le fichier " + fileToAdd.name + " n'a pas été ajouté : le fichier dépasse les 5Mo";
			}
		},
		bannedMails: ['@yopmail', '@lidte', '@vmani', '@seacob', '@firemailbox', '@tzymail', '@jourrapide', '@armyspy', '@cuvox', '@dayrep', '@einrot', '@fleckens', '@gustr', '@rhyta', '@superrito', '@teleworm'],
		inputs: {
			"firstname": {
				type: "text",
				required: true,
				valid: false,
				error: ""
			},
			"lastname": {
				type: "text",
				required: true,
				valid: false,
				error: ""
			},
			"company": {
				type: "alphanum",
				required: false,
				valid: false,
				error: ""
			},
			"email": {
				type: "email",
				required: true,
				valid: false,
				error: ""
			},
			"phone": {
				type: "number",
				required: true,
				valid: false,
				error: ""
			},
			"address": {
				valid: false,
				error: "",
				empty: true,
				isFocused: false,
				"street": {
					type: "text",
					required: false,
					valid: false,
					error: ""
				},
				"num": {
					type: "number",
					required: false,
					valid: false,
					error: ""
				},
				"npa": {
					type: "number",
					required: false,
					valid: false,
					error: ""
				},
				"city": {
					type: "text",
					required: false,
					valid: false,
					error: ""
				}
			},
			"subject": {
				type: "alphanum",
				required: true,
				valid: false,
				error: ""
			},
			"message": {
				type: "alphanum",
				required: true,
				valid: false,
				error: ""
			},
		},
		verifyData: (id, isAddress) => {
			let data = document.querySelector('#'+id).value;
			let type = "text";
			let required = false;
			
			if(isAddress) {
				type = form.inputs.address[id].type;
				required = form.inputs.address[id].required;
			} else {
				type = form.inputs[id].type;
				required = form.inputs[id].required;
			}

			let valid = false;
			let error = "";

			if ((data == '' || data == null || data.trim() == '') && required) {
				error = "Champ vide";
			} else if((data == '' || data == null || data.trim() == '')) {
				if(isAddress) {
					form.inputs.address[id].valid = false;
					form.inputs.address[id].error = "";
					document.querySelector('#'+id).value = "";
				} else {
					form.inputs[id].valid = false;
					form.inputs[id].error = "";
					document.querySelector('#'+id).value = "";
				}
				return;
			} else {
				var r = null;
				var e = null;
				switch (type) {
					case "alphanum":
						r = /^[a-zA-Z\s\-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ€'.,;:!?()0-9]+$/gm;
						e = /[^a-zA-Z\s\-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ€'.,;:!?()0-9]+/igm;
						break;
					case "text":
						r = /^[a-zA-Z\s\-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ€'.,;:!?()]+$/gm;
						e = /[^a-zA-Z\s\-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ€'.,;:!?()]+/igm;
						break;
					case "number":
						r = /^[\d+ ]+$/gm;
						e = /[^\d+ ]+/gm;
						break;
					case "email":
						r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm;
						break;
				}
				var test = r.test(data);

				if (test == true) {
					if (type == "email") {
						let isBanned = false;
						form.bannedMails.forEach(b => {
							if (data.includes(b)) isBanned = true;
						});
						if (isBanned == false) {
							valid = true;
						} else {
							error = "Adresse mail invalide";
						}
					} else {
						valid = true;
					}
				} else if (test == false) {
					if (type == "email") {
						error = "Adresse mail invalide";
					} else {
						if (data.match(e) != null) {
							if(isAddress) {
								error = data.match(e);
							} else {
								error = 'Caractères invalides: ' + data.match(e);
							}
						}
					}
				}
			}

			if(isAddress) {
				form.inputs.address[id].valid = valid;
				form.inputs.address[id].error = error;
				form.verifyAddress();
			} else {
				form.inputs[id].valid = valid;
				form.inputs[id].error = error;
			}

			form.verifyForm();
		},
		verifyAddress: () => {
			if(form.inputs.address.city.valid && form.inputs.address.num.valid && form.inputs.address.street.valid && form.inputs.address.npa.valid) {
				form.inputs.address.valid = true;
				form.inputs.address.empty = false;
				form.inputs.address.error = "";
			} else if(form.inputs.address.city.error == "" && form.inputs.address.street.error == "" && form.inputs.address.npa.error == "" && form.inputs.address.num.error == "") {
				form.inputs.address.valid = false;
				form.inputs.address.empty = true;
				form.inputs.address.error = "";
			} else {
				form.inputs.address.valid = false;
				form.inputs.address.empty = false;
				form.inputs.address.error = 'Caractères invalides: ' + form.inputs.address.city.error + form.inputs.address.npa.error + form.inputs.address.street.error + form.inputs.address.num.error;
			}
		},
		verifyForm: () => {
			let isValid = true;
			for (var input in form.inputs) {
				if (form.inputs.hasOwnProperty(input)) {
					if(form.inputs[input].required) {
						if(form.inputs[input].error != "" || document.getElementById(input).value == "" || document.getElementById(input).value == null) {
							isValid = false;
						}
					}
					if(!form.inputs[input].required && form.inputs[input].error != "") {
						isValid = false;
					}
				}
			}
			form.isValid = isValid;
		},
		resetForm: () => {
			document.querySelector('#resetBtn').click();
			for (var input in form.inputs) {
				if (form.inputs.hasOwnProperty(input)) {
					form.inputs[input].valid = false;
					form.inputs[input].error = "";
				}
			}
		},
		sendForm: async () => {
			form.formState = 'input';
			form.recaptchaErr = "";
			form.messageSent = false;

			var data = new FormData(document.getElementById('contact-form'));
			data.enctype = 'multipart/form-data';
			form.formState = 'sending';

			var res = await fetch("mailer.php", {
				method: "POST",
				body: data
			});

			var resData = await res.json();

			if(resData.status == "ok") {
				form.messageSent = true;
			} else {
				form.mailErr = "Une erreur est survenue";
			}

			form.formState = 'input';
			form.resetForm();

			return false;
		}
	};	
</script>

{#if form.recaptchaErr != ""}
	<div class="form-error">
		<div class="alert alert-danger alert-dismissible">
			<button type="button" class="btn-close" on:click={() => {form.recaptchaErr = ""}}></button>
			{form.recaptchaErr}
		</div>
	</div>
{/if}
{#if form.messageSent == true}
	<div class="form-success">
		<div class="alert alert-success alert-dismissible">
			<button type="button" class="btn-close" data-bs-dismiss="alert" on:click={() => {form.messageSent = false}}></button>
			Votre message a bien été envoyé !
		</div>
	</div>
{/if}
{#if form.formState == 'sending'}
	<div class="spinner-container">
		<div class="spinner-border"></div>
		<span class="spinner-text">Envoi de votre message</span>
	</div>
{/if}
<button on:click={form.sendForm()} type="button" style="display:none;" id="submitFormBtn">0</button>
<form action="" class:sending={form.formState == "sending"} id="contact-form" method="POST">
	<button type="reset" id="resetBtn" style="display:none">r</button>
	<input type="number" name="nois" id="nois" style="display:none">
	<div class="row">
		<div class="col-sm-5">
			<div class="form-input-container">
				<label for="firstname" class="form-label">
					<span>Prénom</span>
					<span class="input-status" class:required={form.inputs['firstname'].error == "" && form.inputs['firstname'].required && !form.inputs['firstname'].valid} class:valid={form.inputs['firstname'].error == "" && form.inputs['firstname'].valid} class:error={form.inputs['firstname'].error != "" && !form.inputs['firstname'].valid}>
						{#if form.inputs['firstname'].error == "" && form.inputs['firstname'].required && !form.inputs['firstname'].valid}
							*
						{:else if form.inputs['firstname'].error == "" && form.inputs['firstname'].valid}
							<i class="fas fa-check"></i>
						{:else if form.inputs['firstname'].error != "" && !form.inputs['firstname'].valid}
							<i class="fas fa-times"></i> {form.inputs['firstname'].error}
						{/if}
					</span>
				</label>
				<input type="text" class="form-control" id="firstname" name="firstname" required on:change={() => {form.verifyData('firstname', false)}} class:valid={form.inputs['firstname'].valid} class:error={form.inputs['firstname'].error != "" && !form.inputs['firstname'].valid}>
			</div>
		</div>
		<div class="col-sm-7">
			<div class="form-input-container">
				<label for="lastname" class="form-label">
					<span>Nom</span>
					<span class="input-status" class:required={form.inputs['lastname'].error == "" && form.inputs['lastname'].required && !form.inputs['lastname'].valid} class:valid={form.inputs['lastname'].error == "" && form.inputs['lastname'].valid} class:error={form.inputs['lastname'].error != "" && !form.inputs['lastname'].valid}>
						{#if form.inputs['lastname'].error == "" && form.inputs['lastname'].required && !form.inputs['lastname'].valid}
							*
						{:else if form.inputs['lastname'].error == "" && form.inputs['lastname'].valid}
							<i class="fas fa-check"></i>
						{:else if form.inputs['lastname'].error != "" && !form.inputs['lastname'].valid}
							<i class="fas fa-times"></i> {form.inputs['lastname'].error}
						{/if}
					</span>
				</label>
				<input type="text" class="form-control" id="lastname" name="lastname" required on:change={() => {form.verifyData('lastname', false)}} class:valid={form.inputs['lastname'].valid} class:error={form.inputs['lastname'].error != "" && !form.inputs['lastname'].valid}>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-7">
			<div class="form-input-container">
				<label for="email" class="form-label">
					<span>Adresse e-mail</span>
					<span class="input-status" class:required={form.inputs['email'].error == "" && form.inputs['email'].required && !form.inputs['email'].valid} class:valid={form.inputs['email'].error == "" && form.inputs['email'].valid} class:error={form.inputs['email'].error != "" && !form.inputs['email'].valid}>
						{#if form.inputs['email'].error == "" && form.inputs['email'].required && !form.inputs['email'].valid}
							*
						{:else if form.inputs['email'].error == "" && form.inputs['email'].valid}
							<i class="fas fa-check"></i>
						{:else if form.inputs['email'].error != "" && !form.inputs['email'].valid}
							<i class="fas fa-times"></i> {form.inputs['email'].error}
						{/if}
					</span>
				</label>
				<input type="email" class="form-control" name="email" id="email" required on:change={() => {form.verifyData('email', false)}} class:valid={form.inputs['email'].valid} class:error={form.inputs['email'].error != "" && !form.inputs['email'].valid}>
			</div>
		</div>
		<div class="col-sm-5">
			<div class="form-input-container">
				<label for="phone" class="form-label">
					<span>Téléphone</span>
					<span class="input-status" class:required={form.inputs['phone'].error == "" && form.inputs['phone'].required && !form.inputs['phone'].valid} class:valid={form.inputs['phone'].error == "" && form.inputs['phone'].valid} class:error={form.inputs['phone'].error != "" && !form.inputs['phone'].valid}>
						{#if form.inputs['phone'].error == "" && form.inputs['phone'].required && !form.inputs['phone'].valid}
							*
						{:else if form.inputs['phone'].error == "" && form.inputs['phone'].valid}
							<i class="fas fa-check"></i>
						{:else if form.inputs['phone'].error != "" && !form.inputs['phone'].valid}
							<i class="fas fa-times"></i> {form.inputs['phone'].error}
						{/if}
					</span>
				</label>
				<input type="phone" class="form-control" name="phone" id="phone" required on:change={() => {form.verifyData('phone', false)}} class:valid={form.inputs['phone'].valid} class:error={form.inputs['phone'].error != "" && !form.inputs['phone'].valid}>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<div class="form-input-container">
				<label for="address" class="form-label">
					<span>Adresse</span>
					<span class="input-status" class:valid={form.inputs['address'].valid} class:error={!form.inputs['address'].valid}>
						{#if form.inputs['address'].error == "" && form.inputs['address'].valid}
							<i class="fas fa-check"></i>
						{:else if form.inputs['address'].error != "" && !form.inputs['address'].valid}
							<i class="fas fa-times"></i> {form.inputs['address'].error}
						{/if}
					</span>
				</label>
				<div class="multi-input-container" name="address" id="address">
					<div class="multi-inputs">
						<input type="text" name="street" id="street" placeholder="Rue" class="a40" on:focus={() => {form.inputs.address.isFocused = true;}} on:blur={() => {form.inputs.address.isFocused = false;}} on:change={form.verifyData('street', true)}>
						<span class="divider">/</span>
						<input type="text" name="num" id="num" placeholder="Numéro" class="a10" on:focus={() => {form.inputs.address.isFocused = true;}} on:blur={() => {form.inputs.address.isFocused = false;}} on:change={form.verifyData('num', true)}>
						<span class="divider">/</span>
						<input type="text" name="npa" id="npa" placeholder="NPA" class="a10" on:focus={() => {form.inputs.address.isFocused = true;}} on:blur={() => {form.inputs.address.isFocused = false;}} on:change={form.verifyData('npa', true)}>
						<span class="divider">/</span>
						<input type="text" name="city" id="city" placeholder="Ville" class="a40" on:focus={() => {form.inputs.address.isFocused = true;}} on:blur={() => {form.inputs.address.isFocused = false;}} on:change={form.verifyData('city', true)}>
					</div>
					<div class="multi-input-background" class:focused={form.inputs.address.isFocused} class:valid={form.inputs['address'].valid} class:error={!form.inputs['address'].valid && !form.inputs['address'].empty}></div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-5">
			<div class="form-input-container">
				<label for="company" class="form-label">
					<span>Entreprise</span>
					<span class="input-status" class:valid={form.inputs['company'].error == "" && form.inputs['company'].valid} class:error={form.inputs['company'].error != "" && !form.inputs['company'].valid}>
						{#if form.inputs['company'].error == "" && form.inputs['company'].valid}
							<i class="fas fa-check"></i>
						{:else if form.inputs['company'].error != "" && !form.inputs['company'].valid}
							<i class="fas fa-times"></i> {form.inputs['company'].error}
						{/if}
					</span>
				</label>
				<input type="text" class="form-control" name="company" id="company" on:change={() => {form.verifyData('company', false)}} class:valid={form.inputs['company'].valid} class:error={form.inputs['company'].error != "" && !form.inputs['company'].valid}>
			</div>
		</div>
		<div class="col-sm-7">
			<div class="form-input-container">
				<label for="subject" class="form-label">
					<span>Sujet</span>
					<span class="input-status" class:required={form.inputs['subject'].error == "" && form.inputs['subject'].required && !form.inputs['subject'].valid} class:valid={form.inputs['subject'].error == "" && form.inputs['subject'].valid} class:error={form.inputs['subject'].error != "" && !form.inputs['subject'].valid}>
						{#if form.inputs['subject'].error == "" && form.inputs['subject'].required && !form.inputs['subject'].valid}
							*
						{:else if form.inputs['subject'].error == "" && form.inputs['subject'].valid}
							<i class="fas fa-check"></i>
						{:else if form.inputs['subject'].error != "" && !form.inputs['subject'].valid}
							<i class="fas fa-times"></i> {form.inputs['subject'].error}
						{/if}
					</span>
				</label>
				<input type="text" class="form-control" name="subject" id="subject" required on:change={() => {form.verifyData('subject', false)}} class:valid={form.inputs['subject'].valid} class:error={form.inputs['subject'].error != "" && !form.inputs['subject'].valid}>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<div class="form-input-container">
				<label for="message" class="form-label">
					<span>Message</span>
					<span class="input-status" class:required={form.inputs['message'].error == "" && form.inputs['message'].required && !form.inputs['message'].valid} class:valid={form.inputs['message'].error == "" && form.inputs['message'].valid} class:error={form.inputs['message'].error != "" && !form.inputs['message'].valid}>
						{#if form.inputs['message'].error == "" && form.inputs['message'].required && !form.inputs['message'].valid}
							*
						{:else if form.inputs['message'].error == "" && form.inputs['message'].valid}
							<i class="fas fa-check"></i>
						{:else if form.inputs['message'].error != "" && !form.inputs['message'].valid}
							<i class="fas fa-times"></i> {form.inputs['message'].error}
						{/if}
					</span>
				</label>
				<textarea class="form-control" name="message" id="message" cols="30" rows="10" on:change={() => {form.verifyData('message', false)}} class:valid={form.inputs['message'].valid} class:error={form.inputs['message'].error != "" && !form.inputs['message'].valid}></textarea>
			</div>
		</div>
	</div>
	<div class="row mb-4">
		<div class="col-sm-12">

			<input type="file" class="hidden" name="files1" id="files1" accept=".jpg,.jpeg,.png,.pdf,.svg,.docx,.xlsx,.xls,.zip,.rar,.7z" on:change={form.addFiles('files1')}>
			<input type="file" class="hidden" name="files2" id="files2" accept=".jpg,.jpeg,.png,.pdf,.svg,.docx,.xlsx,.xls,.zip,.rar,.7z" on:change={form.addFiles('files2')}>
			<input type="file" class="hidden" name="files3" id="files3" accept=".jpg,.jpeg,.png,.pdf,.svg,.docx,.xlsx,.xls,.zip,.rar,.7z" on:change={form.addFiles('files3')}>

			<label class="form-label file-label" for=""><span>Pièces jointes {form.filesLength}/{form.filesMax}</span> <i class="fas fa-question-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="3 Fichiers de 5Mo chacun maximum. Format acceptés : jpg; jpeg; png; pdf; svg; docx; xlsx; xls; zip; rar; 7z"></i></label>
			<div class="files-container">
				<div class="files-input" class:err={form.fileError != ""}>
					<button class="btn btn-primary file-btn" class:disabled={form.filesLength > 2} type="button" on:click={() => {document.getElementById(form.fileInputState).click();}}>
					<span>Ajouter un fichier</span> <i class="fas fa-file-alt"></i></button>
					<ul class="files-list">
						{#each form.files as file, index}
							<li class="file">
								<i class="fas fa-times" on:click={form.removeFile(index, file.id)}></i>
								<span>{file.name}</span>
							</li>
						{/each}
					</ul>
				</div>
				<span class="error">{form.fileError}</span>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12">
			<button type='button' class="btn btn-success float-end g-recaptcha" class:disabled={!form.isValid} data-sitekey="6LfVhmMeAAAAACqHe4iD6Al86MqDXKOaTgbMNx2L" data-callback="onSubmit"><i class="fas fa-paper-plane"></i> Envoyer</button>
		</div>
	</div>
</form>

<style>
	
</style>